import React from 'react';
import {graphql, useStaticQuery} from "gatsby";
import Testimonial from "./Testimonial";

export default () => {
    const { allSanityTestimonial } = useStaticQuery(graphql`
        query {
            allSanityTestimonial(sort: {fields: date, order: DESC}) {
                edges {
                  node {
                    author
                    date
                    location
                    quote
                  }
                }
            }
        }
    `);

    const testimonials = allSanityTestimonial.edges.map((testimonial) => {
        return {
            ...testimonial.node,
            date: new Date(Date.parse(testimonial.node.date))
        }
    });

    return (
        <div className={"testimonials w-full"}>
            <h3 className={"text-lg text-center mt-12"}>Testimonials</h3>
            <ul className={"testimonials-list bg-black border border-gray-800 mt-4"}>
                {testimonials.map((testimonial, index) =>
                    <Testimonial key={index} testimonial={testimonial}/>
                )}
            </ul>
        </div>
    )
}
