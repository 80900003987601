import React from 'react';
import DateFormat from "../utils/DateFormat";

export default ({ testimonial }) => (
    <li className={"testimonial my-3 p-4 px-8 text-center border-black w-full"}>
        <p className={"quote"}>
            {testimonial.quote}
        </p>
        <small>
            {`${testimonial.author} - ${DateFormat(testimonial.date)}`}
        </small>
    </li>
)
